import React from "react";
import _ from "lodash";
import { NavLink } from "react-router-dom";
import { Layout, Menu, Icon } from "antd";
import styles from "./index.module.scss";
import classnames from "classnames";
import paths from "app/utils/paths";
import { isAccessLevel } from "app/utils/accessLevel/accessLevel";
import { imageUrl } from "app/utils/image";

const { Sider } = Layout;
const { SubMenu } = Menu;

interface IProps {
  collapsed: boolean;
  adminInfo: any;
}

const boardLogo = imageUrl("logos/board-infinity-white.png");
const infinityLogo = imageUrl("logos/infinity-white.png");

const SideBar: React.FunctionComponent<IProps> = ({ collapsed, adminInfo }) => {
  return (
    <Sider trigger={null} collapsible={true} collapsed={collapsed} width={300} className={styles.sideBar}>
      <div className={classnames(styles.logo, { [styles.logoCollapsed]: collapsed })}>
        <NavLink exact={true} to={paths.dashboard.path}>
          <img src={collapsed ? infinityLogo : boardLogo} alt="bi-infinity-logo" />
        </NavLink>
      </div>

      <Menu theme="dark" mode="inline" className={styles.menuItems}>
        <Menu.Item key="dashboard">
          <Icon type="home" theme="filled" />
          <span className={styles.text}>Dashboard</span>
          <NavLink exact={true} to={paths.dashboard.path} />
        </Menu.Item>

        {isAccessLevel(paths.learningOperations.overallAnalytics.accessLevel) && (
          <SubMenu
            key="analytics"
            className={collapsed ? classnames(styles.submenu, styles.submenuCollapsed) : styles.submenu}
            title={
              <span className={styles.text}>
                <Icon type="bar-chart" />
                <span>Analytics</span>
              </span>
            }>
            <SubMenu
              key="learning-operations"
              className={styles.text}
              title={
                <span className={styles.text}>
                  <span>Learning Operations</span>
                </span>
              }>
              <Menu.Item key={paths.learningOperations.overallAnalytics.path}>
                <NavLink to={paths.learningOperations.overallAnalytics.path} className={styles.text}>
                  Overall
                </NavLink>
              </Menu.Item>
              <Menu.Item key={paths.learningOperations.customerAnalytics.path}>
                <NavLink to={paths.learningOperations.customerAnalytics.path} className={styles.text}>
                  Customer Analytics (CRM)
                </NavLink>
              </Menu.Item>
              <Menu.Item key={paths.learningOperations.coachingMarketPlaceAnalytics.path}>
                <NavLink to={paths.learningOperations.coachingMarketPlaceAnalytics.path} className={styles.text}>
                  Coaching Marketplace
                </NavLink>
              </Menu.Item>
              {isAccessLevel(paths.coachInvite.accessLevel) && (
                <Menu.Item key={paths.learningOperations.oneOnOneLearningFoundation.path}>
                  <span className={styles.text}>
                    <span>1:1 Coaching Foundation</span>
                  </span>
                  <NavLink to={paths.learningOperations.oneOnOneLearningFoundation.path} className={styles.text} />
                </Menu.Item>
              )}
            </SubMenu>
          </SubMenu>
        )}

        {isAccessLevel(paths.allSessions.accessLevel) && (
          <SubMenu
            key="scheduling"
            className={collapsed ? classnames(styles.submenu, styles.submenuCollapsed) : styles.submenu}
            title={
              <span className={styles.text}>
                <Icon type="schedule" theme="filled" />
                <span>1:1 Sessions</span>
              </span>
            }>
            <Menu.Item key={paths.allSessions.path}>
              <NavLink to={paths.allSessions.path} className={styles.text}>
                All
              </NavLink>
            </Menu.Item>
            <Menu.Item key={paths.pendingSessions.path}>
              <NavLink to={paths.pendingSessions.path} className={styles.text}>
                Pending
              </NavLink>
            </Menu.Item>
            <Menu.Item key={paths.upcomingSessions.path}>
              <NavLink to={paths.upcomingSessions.path} className={styles.text}>
                Upcoming
              </NavLink>
            </Menu.Item>
            <Menu.Item key={paths.scheduledSessions.path}>
              <NavLink to={paths.scheduledSessions.path} className={styles.text}>
                Scheduled
              </NavLink>
            </Menu.Item>
            <Menu.Item key={paths.ongoingSessions.path}>
              <NavLink to={paths.ongoingSessions.path} className={styles.text}>
                Ongoing
              </NavLink>
            </Menu.Item>
            <Menu.Item key={paths.feedbackPending.path}>
              <NavLink to={paths.feedbackPending.path} className={styles.text}>
                Feedback Pending
              </NavLink>
            </Menu.Item>
            <Menu.Item key={paths.completedSessions.path}>
              <NavLink to={paths.completedSessions.path} className={styles.text}>
                Completed
              </NavLink>
            </Menu.Item>
            <Menu.Item key={paths.elapsedSessions.path}>
              <NavLink to={paths.elapsedSessions.path} className={styles.text}>
                Elapsed
              </NavLink>
            </Menu.Item>
            <Menu.Item key={paths.rescheduleRequestsSessions.path}>
              <NavLink to={paths.rescheduleRequestsSessions.path} className={styles.text}>
                Reschedule Requests
              </NavLink>
            </Menu.Item>
          </SubMenu>
        )}

        {isAccessLevel(paths.CreateLmsSession.accessLevel) && (
          <SubMenu
            key="/SessionV3"
            className={collapsed ? classnames(styles.submenu, styles.submenuCollapsed) : styles.submenu}
            title={
              <span className={styles.text}>
                <Icon type="laptop" />
                <span>Session V3</span>
              </span>
            }>
            {/* <Menu.Item key={paths.CreateLmsSession.path}>
              <NavLink to={paths.CreateLmsSession.path} className={styles.text}>
                Create LMS Session
              </NavLink>
            </Menu.Item> */}

            {isAccessLevel(paths.LmsSessions.accessLevels) && (
              <SubMenu key="lms-session" className={styles.text} title={<span className={styles.text}>LMS Sessions</span>}>
                <Menu.Item>
                  <NavLink to={paths.LmsSessions.all.path} className={styles.text}>
                    All
                  </NavLink>
                </Menu.Item>
                <Menu.Item>
                  <NavLink to={paths.LmsSessions.cmsSyncNeeded.path} className={styles.text}>
                    CMS Sync Needed
                  </NavLink>
                </Menu.Item>
                <Menu.Item>
                  <NavLink to={paths.LmsSessions.readyToScheduled.path} className={styles.text}>
                    Ready to Schedule
                  </NavLink>
                </Menu.Item>
                <Menu.Item>
                  <NavLink to={paths.LmsSessions.scheduled.path} className={styles.text}>
                    Scheduled
                  </NavLink>
                </Menu.Item>
                <Menu.Item>
                  <NavLink to={paths.LmsSessions.ongoing.path} className={styles.text}>
                    Started
                  </NavLink>
                </Menu.Item>
                <Menu.Item>
                  <NavLink to={paths.LmsSessions.completed.path} className={styles.text}>
                    Completed
                  </NavLink>
                </Menu.Item>
                <Menu.Item>
                  <NavLink to={paths.LmsSessions.cancelled.path} className={styles.text}>
                    Cancelled
                  </NavLink>
                </Menu.Item>
                <Menu.Item>
                  <NavLink to={paths.LmsSessions.abandoned.path} className={styles.text}>
                    Abandoned
                  </NavLink>
                </Menu.Item>
                <Menu.Item>
                  <NavLink to={paths.LmsSessions.elapsed.path} className={styles.text}>
                    Elapsed
                  </NavLink>
                </Menu.Item>
              </SubMenu>
            )}
            <SubMenu className={styles.text} title={<span className={styles.text}>1:1 Sessions</span>}>
              <Menu.Item>
                <NavLink to={paths.sessions1_1.all.path} className={styles.text}>
                  All
                </NavLink>
              </Menu.Item>
              <Menu.Item key={paths.sessions1_1.started.path}>
                <NavLink to={paths.sessions1_1.started.path} className={styles.text}>
                  Started
                </NavLink>
              </Menu.Item>

              <Menu.Item key={paths.sessions1_1.readyToSchedule.path}>
                <NavLink to={paths.sessions1_1.readyToSchedule.path} className={styles.text}>
                  Ready to Schedule
                </NavLink>
              </Menu.Item>
              <Menu.Item key={paths.sessions1_1.rescheduleRequest.path}>
                <NavLink to={paths.sessions1_1.rescheduleRequest.path} className={styles.text}>
                  Reschedule Request
                </NavLink>
              </Menu.Item>
              <Menu.Item key={paths.sessions1_1.scheduled.path}>
                <NavLink to={paths.sessions1_1.scheduled.path} className={styles.text}>
                  Scheduled
                </NavLink>
              </Menu.Item>
              <Menu.Item key={paths.sessions1_1.pendingTimeSlots.path}>
                <NavLink to={paths.sessions1_1.pendingTimeSlots.path} className={styles.text}>
                  Pending Time Slot
                </NavLink>
              </Menu.Item>
              <Menu.Item key={paths.sessions1_1.coachNotAssigned.path}>
                <NavLink to={paths.sessions1_1.coachNotAssigned.path} className={styles.text}>
                  Coach not Assigned
                </NavLink>
              </Menu.Item>

              <Menu.Item key={paths.sessions1_1.completed.path}>
                <NavLink to={paths.sessions1_1.completed.path} className={styles.text}>
                  Completed
                </NavLink>
              </Menu.Item>

              <Menu.Item key={paths.sessions1_1.abandoned.path}>
                <NavLink to={paths.sessions1_1.abandoned.path} className={styles.text}>
                  Abandoned
                </NavLink>
              </Menu.Item>
              <Menu.Item key={paths.sessions1_1.elapsed.path}>
                <NavLink to={paths.sessions1_1.elapsed.path} className={styles.text}>
                  Elapsed
                </NavLink>
              </Menu.Item>
              <Menu.Item key={paths.sessions1_1.cancelled.path}>
                <NavLink to={paths.sessions1_1.cancelled.path} className={styles.text}>
                  Cancelled
                </NavLink>
              </Menu.Item>
            </SubMenu>

            <SubMenu key="common-session" className={styles.text} title={<span className={styles.text}>Common</span>}>
              <Menu.Item>
                <NavLink to={paths.CommonSessionV3.bulkFeedback.path} className={styles.text}>
                  Bulk Feedback Update
                </NavLink>
              </Menu.Item>
              <Menu.Item>
                <NavLink to={paths.CommonSessionV3.bulkFeedbackGenerate.path} className={styles.text}>
                  Bulk Feedback Generate
                </NavLink>
              </Menu.Item>
              <Menu.Item>
                <NavLink to={paths.CommonSessionV3.bulkProviderUpdate.path} className={styles.text}>
                  Bulk Provider Update
                </NavLink>
              </Menu.Item>
              <Menu.Item>
                <NavLink to={paths.CommonSessionV3.videoNotPresentSession.path} className={styles.text}>
                  Session videos
                </NavLink>
              </Menu.Item>
            </SubMenu>
          </SubMenu>
        )}

        {/* Generic Sessions */}
        {isAccessLevel(paths.CreateGenericSession.accessLevel) && (
          <SubMenu
            key="genericSessions"
            className={collapsed ? classnames(styles.submenu, styles.submenuCollapsed) : styles.submenu}
            title={
              <span className={styles.text}>
                <Icon type="schedule" theme="filled" />
                <span>Generic Sessions</span>
              </span>
            }>
            {/* <Menu.Item key={paths.CreateGenericSession.path}>
              <NavLink to={paths.CreateGenericSession.path} className={styles.text}>
                Create Generic Session
              </NavLink>
            </Menu.Item> */}

            {isAccessLevel(paths.GenericSessions.accessLevels) && (
              <SubMenu key="generic-session" className={styles.text} title={<span className={styles.text}>Sessions</span>}>
                <Menu.Item>
                  <NavLink to={paths.GenericSessions.all.path} className={styles.text}>
                    All
                  </NavLink>
                </Menu.Item>
                <Menu.Item>
                  <NavLink to={paths.GenericSessions.readyToScheduled.path} className={styles.text}>
                    Ready to Schedule
                  </NavLink>
                </Menu.Item>
                <Menu.Item>
                  <NavLink to={paths.GenericSessions.scheduled.path} className={styles.text}>
                    Scheduled
                  </NavLink>
                </Menu.Item>
                <Menu.Item>
                  <NavLink to={paths.GenericSessions.ongoing.path} className={styles.text}>
                    Started
                  </NavLink>
                </Menu.Item>
                <Menu.Item>
                  <NavLink to={paths.GenericSessions.completed.path} className={styles.text}>
                    Completed
                  </NavLink>
                </Menu.Item>
                <Menu.Item>
                  <NavLink to={paths.GenericSessions.cancelled.path} className={styles.text}>
                    Cancelled
                  </NavLink>
                </Menu.Item>
                <Menu.Item>
                  <NavLink to={paths.GenericSessions.onHold.path} className={styles.text}>
                    On Hold
                  </NavLink>
                </Menu.Item>
                <Menu.Item>
                  <NavLink to={paths.GenericSessions.abandoned.path} className={styles.text}>
                    Abandoned
                  </NavLink>
                </Menu.Item>
                <Menu.Item>
                  <NavLink to={paths.GenericSessions.elapsed.path} className={styles.text}>
                    Elapsed
                  </NavLink>
                </Menu.Item>
              </SubMenu>
            )}
          </SubMenu>
        )}

        {/* Batch Management */}
        {isAccessLevel(paths.pendingBatches.accessLevel) && (
          <SubMenu
            key="batch-management"
            className={collapsed ? classnames(styles.submenu, styles.submenuCollapsed) : styles.submenu}
            title={
              <span className={styles.text}>
                <Icon type="number" />
                <span>Batch Management</span>
              </span>
            }>
            <Menu.Item key={paths.pendingBatches.path}>
              <NavLink to={paths.pendingBatches.path} className={styles.text}>
                Pending Batches
              </NavLink>
            </Menu.Item>
            <Menu.Item key={paths.assignedBatches.path}>
              <NavLink to={paths.assignedBatches.path} className={styles.text}>
                Assigned Batches
              </NavLink>
            </Menu.Item>
            {isAccessLevel(paths.bulkLmsComponent.accessLevel) && (
              <Menu.Item key={paths.bulkLmsComponent.path}>
                <NavLink to={paths.bulkLmsComponent.path} className={styles.text}>
                  Bulk Lms Components
                </NavLink>
              </Menu.Item>
            )}
          </SubMenu>
        )}

        {/* E2E Management */}
        {isAccessLevel(paths.e2eManagement.accessLevel) && (
          <SubMenu
            key="e2e-management"
            className={collapsed ? classnames(styles.submenu, styles.submenuCollapsed) : styles.submenu}
            title={
              <span className={styles.text}>
                <Icon type="desktop" />
                <span>E2E Management</span>
              </span>
            }>
            {isAccessLevel(paths.e2eManagement.projects.accessLevel) && (
              <Menu.Item key={paths.e2eManagement.projects.path}>
                <NavLink to={paths.e2eManagement.projects.path} className={styles.text} />
                Projects
              </Menu.Item>
            )}
            {isAccessLevel(paths.e2eManagement.bulkProjectAssignment.accessLevel) && (
              <Menu.Item key={paths.e2eManagement.bulkProjectAssignment.path}>
                <NavLink to={paths.e2eManagement.bulkProjectAssignment.path} className={styles.text} />
                Bulk Project Assignment
              </Menu.Item>
            )}
            {isAccessLevel(paths.e2eManagement.uploadFinalProjectMarks.accessLevel) && (
              <Menu.Item key={paths.e2eManagement.uploadFinalProjectMarks.path}>
                <NavLink to={paths.e2eManagement.uploadFinalProjectMarks.path} className={styles.text} />
                Upload Final Project Marks
              </Menu.Item>
            )}
          </SubMenu>
        )}

        {isAccessLevel(paths.sessions1_1.doubtSessions.accessLevel) && (
          <Menu.Item key={paths.sessions1_1.doubtSessions.path}>
            <span className={styles.text}>
              <Icon type="laptop" />
              <span>Create Doubt Sessions</span>
            </span>
            <NavLink to={paths.sessions1_1.doubtSessions.path} className={styles.text} />
          </Menu.Item>
        )}

        {isAccessLevel(paths.webinars.accessLevel) && (
          <Menu.Item key={paths.webinars.path}>
            <span className={styles.text}>
              <Icon type="play-square" />
              <span>Webinars</span>
            </span>
            <NavLink to={paths.webinars.path} className={styles.text} />
          </Menu.Item>
        )}
        {isAccessLevel(paths.userAddProduct.accessLevel) && (
          <Menu.Item key={paths.userAddProduct.path}>
            <span className={styles.text}>
              <Icon type="shopping" />
              <span>Add Product</span>
            </span>
            <NavLink to={paths.userAddProduct.path} className={styles.text} />
          </Menu.Item>
        )}
        {isAccessLevel(paths.uploadLead.accessLevel) && (
          <SubMenu
            key="upload-lead"
            className={collapsed ? classnames(styles.submenu, styles.submenuCollapsed) : styles.submenu}
            title={
              <span className={styles.text}>
                <Icon type="usergroup-add" />
                <span>Upload Lead</span>
              </span>
            }>
            <Menu.Item>
              <span className={styles.text}>
                <span>Internal Lead Upload</span>
              </span>
              <NavLink to={paths.uploadLead.path} className={styles.text} />
            </Menu.Item>
            {/* Web lead Upload */}
            <Menu.Item>
              <span className={styles.text}>
                <span>Web Lead Upload</span>
              </span>
              <NavLink to={paths.uploadWebLead.path} className={styles.text} />
            </Menu.Item>
          </SubMenu>
        )}
        {isAccessLevel(paths.coachInvite.accessLevel) && (
          <Menu.Item key={paths.coachInvite.path}>
            <span className={styles.text}>
              <Icon type="user-add" />
              <span>Invite Coach</span>
            </span>
            <NavLink to={paths.coachInvite.path} className={styles.text} />
          </Menu.Item>
        )}
        {isAccessLevel(paths.sessionList.accessLevel) && (
          <Menu.Item key={paths.sessionList.path}>
            <span className={styles.text}>
              <Icon type="laptop" />
              <span>Sessions</span>
            </span>
            <NavLink to={paths.sessionList.path} className={styles.text} />
          </Menu.Item>
        )}
        {isAccessLevel(paths.productList.accessLevel) && (
          <Menu.Item key={paths.productList.path}>
            <span className={styles.text}>
              <Icon type="shopping-cart" />
              <span>Products</span>
            </span>
            <NavLink to={paths.productList.path} className={styles.text} />
          </Menu.Item>
        )}
        {isAccessLevel(paths.userList.accessLevel) && (
          <Menu.Item key={paths.userList.path}>
            <span className={styles.text}>
              <Icon type="team" />
              <span>User List</span>
            </span>
            <NavLink to={paths.userList.path} className={styles.text} />
          </Menu.Item>
        )}

        {isAccessLevel(paths.tests.accessLevel) && (
          <Menu.Item key={paths.tests.path}>
            <span className={styles.text}>
              <Icon type="audit" />
              <span>Test Management</span>
            </span>
            <NavLink to={paths.tests.path} className={styles.text} />
          </Menu.Item>
        )}
        {/* {isAccessLevel(paths.companies.accessLevel) && (
          <Menu.Item key={paths.companies.path}>
            <span className={styles.text}>
              <Icon type="cluster" />
              <span>Companies</span>
            </span>
            <NavLink to={paths.companies.path} className={styles.text} />
          </Menu.Item>
        )} */}

        {isAccessLevel(paths.companies.accessLevel) && (
          <SubMenu
            key="companies"
            className={collapsed ? classnames(styles.submenu, styles.submenuCollapsed) : styles.submenu}
            title={
              <span className={styles.text}>
                <Icon type="trademark" />
                <span>Company</span>
              </span>
            }>
            <Menu.Item key={paths.companies.path}>
              <span className={styles.text}>
                <Icon type="cluster" />
                <span>Companies</span>
              </span>
              <NavLink to={paths.companies.path} className={styles.text} />
            </Menu.Item>

            <Menu.Item key={paths.jobs.path}>
              <span className={styles.text}>
                <Icon type="cluster" />
                <span>Jobs</span>
              </span>
              <NavLink to={paths.jobs.path} className={styles.text} />
            </Menu.Item>
          </SubMenu>
        )}

        {isAccessLevel(paths.referralList.accessLevel) && (
          <SubMenu
            key="referrals"
            className={collapsed ? classnames(styles.submenu, styles.submenuCollapsed) : styles.submenu}
            title={
              <span className={styles.text}>
                <Icon type="deployment-unit" />
                <span>Referrals</span>
              </span>
            }>
            {isAccessLevel(paths.referralList.accessLevel) && (
              <Menu.Item key={paths.referralList.path}>
                <NavLink to={paths.referralList.path} className={styles.text}>
                  All Referrals
                </NavLink>
              </Menu.Item>
            )}
            {isAccessLevel(paths.referralRequest.accessLevel) && (
              <Menu.Item key={paths.referralRequest.path}>
                <NavLink to={paths.referralRequest.path} className={styles.text}>
                  Referrals Requests
                </NavLink>
              </Menu.Item>
            )}
          </SubMenu>
        )}

        {isAccessLevel(paths.LeadUp.accessLevel) && (
          <SubMenu
            key="leadup"
            className={collapsed ? classnames(styles.submenu, styles.submenuCollapsed) : styles.submenu}
            title={
              <span className={styles.text}>
                <Icon type="usergroup-add" />
                <span>Lead Up</span>
              </span>
            }>
            <Menu.Item>
              <span className={styles.text}>
                <span>Lead Upload</span>
              </span>
              <NavLink to={paths.LeadUp.path} className={styles.text} />
            </Menu.Item>
          </SubMenu>
        )}

        {isAccessLevel(paths.emailToPlatformId.accessLevel) && (
          <SubMenu
            key="/tools"
            className={collapsed ? classnames(styles.submenu, styles.submenuCollapsed) : styles.submenu}
            title={
              <span className={styles.text}>
                <Icon type="tool" theme="filled" />
                <span>Tools</span>
              </span>
            }>
            {isAccessLevel(paths.mapProductPackages.accessLevel) && (
              <Menu.Item key={paths.mapProductPackages.path}>
                <NavLink to={paths.mapProductPackages.path} className={styles.text}>
                  Map Product Packages
                </NavLink>
              </Menu.Item>
            )}

            {isAccessLevel(paths.userRectification.accessLevel) && (
              <Menu.Item key={paths.userRectification.path}>
                <NavLink to={paths.userRectification.path} className={styles.text}>
                  User Rectification
                </NavLink>
              </Menu.Item>
            )}
            {isAccessLevel(paths.productStats.accessLevel) && (
              <Menu.Item key={paths.productStats.path}>
                <NavLink to={paths.productStats.path} className={styles.text}>
                  Product Stats
                </NavLink>
              </Menu.Item>
            )}
            {isAccessLevel(paths.referralStats.accessLevel) && (
              <Menu.Item key={paths.referralStats.path}>
                <NavLink to={paths.referralStats.path} className={styles.text}>
                  Referral Stats
                </NavLink>
              </Menu.Item>
            )}
            {isAccessLevel(paths.emailToPlatformId.accessLevel) && (
              <Menu.Item key={paths.emailToPlatformId.path}>
                <NavLink to={paths.emailToPlatformId.path} className={styles.text}>
                  Email to PlatformId
                </NavLink>
              </Menu.Item>
            )}
            {isAccessLevel(paths.uploadImages.accessLevel) && (
              <Menu.Item key={paths.uploadImages.path}>
                <NavLink to={paths.uploadImages.path} className={styles.text}>
                  Upload Images or Files
                </NavLink>
              </Menu.Item>
            )}
            {isAccessLevel(paths.messagingQuota.accessLevel) && (
              <Menu.Item key={paths.messagingQuota.path}>
                <NavLink to={paths.messagingQuota.path} className={styles.text}>
                  Messaging Quota
                </NavLink>
              </Menu.Item>
            )}
            {isAccessLevel(paths.bulkUsersDownload.accessLevel) && (
              <Menu.Item key={paths.bulkUsersDownload.path}>
                <NavLink to={paths.bulkUsersDownload.path} className={styles.text}>
                  Bulk Users Download
                </NavLink>
              </Menu.Item>
            )}
            {isAccessLevel(paths.instituteTags.accessLevel) && (
              <Menu.Item key={paths.instituteTags.path}>
                <NavLink to={paths.instituteTags.path} className={styles.text}>
                  Institute Tags
                </NavLink>
              </Menu.Item>
            )}
            {isAccessLevel(paths.bulkAccountCreation.accessLevel) && (
              <Menu.Item key={paths.bulkAccountCreation.path}>
                <NavLink to={paths.bulkAccountCreation.path} className={styles.text}>
                  B2B Bulk Account creation
                </NavLink>
              </Menu.Item>
            )}
          </SubMenu>
        )}

        {isAccessLevel(paths.adminManagement.accessLevel) && (
          <Menu.Item key={paths.adminManagement.path}>
            <span className={styles.text}>
              <Icon type="contacts" theme="filled" />
              <span>Admin Management</span>
            </span>
            <NavLink to={paths.adminManagement.path} className={styles.text} />
          </Menu.Item>
        )}

        {isAccessLevel(paths.Coupon.showCoupon.accessLevel) && (
          <Menu.Item key={paths.Coupon.showCoupon.path}>
            <span className={styles.text}>
              <Icon type="contacts" theme="filled" />
              <span>Coupon Management</span>
            </span>
            <NavLink to={paths.Coupon.showCoupon.path} className={styles.text} />
          </Menu.Item>
        )}

        {isAccessLevel(paths.AlphaBoard.accessLevel) && (
          <Menu.Item key={paths.AlphaBoard.path}>
            <span className={styles.text}>
              <Icon type="shopping" />
              <span>Alpha Board</span>
            </span>
            <NavLink to={paths.AlphaBoard.path} className={styles.text} />
          </Menu.Item>
        )}

        {isAccessLevel(paths.DiscussionForm.RedeemTable.accessLevel) && (
          <SubMenu
            key="/DiscussionForum"
            className={collapsed ? classnames(styles.submenu, styles.submenuCollapsed) : styles.submenu}
            title={
              <span className={styles.text}>
                <Icon type="tool" theme="filled" />
                <span>Discussion Forum</span>
              </span>
            }>
            {isAccessLevel(paths.DiscussionForm.RedeemTable.accessLevel) && (
              <Menu.Item key={paths.DiscussionForm.RedeemTable.path}>
                <span className={styles.text}>
                  <Icon type="play-square" />
                  <span>Redeem Table</span>
                </span>
                <NavLink to={paths.DiscussionForm.RedeemTable.path} className={styles.text} />
              </Menu.Item>
            )}

            {isAccessLevel(paths.DiscussionForm.RedeemList.accessLevel) && (
              <Menu.Item key={paths.DiscussionForm.RedeemList.path}>
                <span className={styles.text}>
                  <Icon type="play-square" />
                  <span>Redeem List</span>
                </span>
                <NavLink to={paths.DiscussionForm.RedeemList.path} className={styles.text} />
              </Menu.Item>
            )}
          </SubMenu>
        )}
      </Menu>
    </Sider>
  );
};

export default SideBar;
